import * as React from 'react';
import { getGlobalEventsLogs } from '@cian/events-log';

import { selectRecommendations, selectRecommendationsStatus } from '../../selectors/recommendations';
import { selectShowHeader } from '../../selectors/showHeader';
import { fetchRecommendations } from '../../slices';
import { useAppDispatch, useAppSelector as useSelector } from '../../types';
import { useApplicationContext } from '../../utils/applicationContext';
import { selectRegionId } from '../../selectors/selectRegionId';
import { selectLandingType } from '../../selectors/landingType';
import { trackMoreButtonClick, trackRecommendationItemClick, trackRecommendationsView } from './tracking';
import { TAnalyticsData, TOfferAnalyticsData, TProduct, TTrapAnalyticsData } from '../../types/recommendation';
import { useVisitedOffersContext } from '@cian/frontend-visited-offers-widget';

const TOPIC_RECOMMENDATIONS = 'recommendations';

export const useRecommendations = () => {
  const {
    custom: { isMobile },
  } = useApplicationContext();

  const { setOfferVisited, isOfferVisited: isVisited } = useVisitedOffersContext();

  const isOfferVisited = React.useCallback((id: number | null) => !!id && isVisited(id), [isVisited]);

  const dispatch = useAppDispatch();
  const recommendations = useSelector(selectRecommendations);
  const recommendationsStatus = useSelector(selectRecommendationsStatus);
  const showHeader = useSelector(selectShowHeader);
  const regionId = useSelector(selectRegionId);
  const landingType = useSelector(selectLandingType);

  const onRecommendationClick = React.useCallback(
    (analyticsData: TAnalyticsData) => {
      const globalLog = getGlobalEventsLogs();

      if (analyticsData.type === 'trap') {
        globalLog.produce(TOPIC_RECOMMENDATIONS, {
          type: 'click',
          value: analyticsData,
        });

        return;
      } else {
        setOfferVisited(analyticsData.offerId);
      }

      trackRecommendationItemClick(landingType, analyticsData.tag, analyticsData.products);
    },
    [landingType, setOfferVisited],
  );

  const onShowTrap = React.useCallback((analyticsData: TTrapAnalyticsData) => {
    const globalLog = getGlobalEventsLogs();

    globalLog.produce(TOPIC_RECOMMENDATIONS, {
      type: 'showTrap',
      value: analyticsData,
    });
  }, []);

  const onShow = React.useCallback(() => {
    const products = recommendations
      .map(({ offerId: id }, index) => ({
        id,
        position: index + 1,
        offerType: 'offer',
        extra: isOfferVisited(id) ? { is_viewes: true } : undefined,
      }))
      .filter(({ id }) => id);

    const modelVersion = (recommendations[0].analyticsData as TOfferAnalyticsData).tag;

    trackRecommendationsView(landingType, modelVersion, products as TProduct[]);
  }, [recommendations, landingType, isOfferVisited]);

  const onMoreClick = React.useCallback(() => {
    const modelVersion = (recommendations[0].analyticsData as TOfferAnalyticsData).tag;

    trackMoreButtonClick(landingType, modelVersion);
  }, [landingType, recommendations]);

  React.useEffect(() => {
    dispatch(fetchRecommendations());
  }, [dispatch]);

  return React.useMemo(
    () => ({
      recommendations,
      showHeader,
      onShowTrap,
      onShow,
      onRecommendationClick,
      recommendationsStatus,
      isMobile,
      regionId,
      onMoreClick,
      isOfferVisited,
    }),
    [
      recommendations,
      showHeader,
      onShowTrap,
      onShow,
      onRecommendationClick,
      recommendationsStatus,
      isMobile,
      regionId,
      onMoreClick,
      isOfferVisited,
    ],
  );
};
