import { IconFunctionalSuccess12, Label } from '@cian/ui-kit';
import * as React from 'react';
import { LabelsBox } from '../../components/LabelsBox';

interface ILabelsContainerProps {
  visited: boolean;
}

export const LabelsContainer: React.FC<ILabelsContainerProps> = ({ visited }) => {
  const labels = [];

  if (visited) {
    labels.push(
      <Label icon={<IconFunctionalSuccess12 color="white_100" />} background="rgba(0, 0, 0, 0.40)" color="white">
        Вы смотрели
      </Label>,
    );
  }

  if (!labels.length) {
    return null;
  }

  return <LabelsBox>{labels}</LabelsBox>;
};
