import { IconBus16, IconWalk16 } from '@cian/ui-kit';
import { UIText1 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './GeoTravelTime.css';
import { ETransportType } from '../../../types/geo';

interface IGeoTravelTimeProps {
  transportType: ETransportType;
  time: number;
}

export const GeoTravelTime: React.FC<IGeoTravelTimeProps> = ({ transportType, time }) => {
  const Icon = transportType === ETransportType.Walk ? IconWalk16 : IconBus16;

  return (
    <div className={styles['container']}>
      <div data-name={transportType} className={styles['icon']}>
        <Icon display="block" color="gray_icons_100" />
      </div>

      <UIText1 display="inline" color="gray60_100">
        {time}
      </UIText1>
    </div>
  );
};
