import { FavoritesAssignWidgetContainer } from '@cian/favorites-assign-widget';
import * as React from 'react';

import {
  trackCreateFolder,
  trackSaveToFolder,
  trackFolderNameClick,
  trackNotificationSaveToFolder,
  trackNotificationChangeFolder,
} from './tracking';
import { selectIsAuthenticated } from '../../selectors/isAuthenticated';
import { useAppDispatch, useAppSelector as useSelector } from '../../types';
import { requestAuthentication } from '../../thunks';
import { useApplicationContext } from '../../utils/applicationContext';
import { selectIsExperimentEnabled } from '../../selectors/selectIsExperimentEnabled';

export const useFavoritesAssignWidget = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isExperimentEnabled = useSelector(selectIsExperimentEnabled);

  const {
    custom: { isMobile },
  } = useApplicationContext();

  const handleLogin = React.useCallback(() => {
    dispatch(requestAuthentication({}));

    return false;
  }, [dispatch]);

  return React.useMemo(
    () => (
      <FavoritesAssignWidgetContainer
        isAuthenticated={isAuthenticated}
        disableNotification={!isMobile}
        onNotificationSaveToFolder={offerId => trackNotificationSaveToFolder({ offerId })}
        onNotificationChangeFolder={offerId => trackNotificationChangeFolder({ offerId })}
        onCreateFolder={trackCreateFolder}
        onSaveOffer={trackSaveToFolder}
        onWaitForLogin={handleLogin}
        onClickFolderName={trackFolderNameClick}
        isExperimentEnabled={isExperimentEnabled}
      />
    ),
    [isAuthenticated, isMobile, handleLogin],
  );
};
