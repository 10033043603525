import { IconTrain16 } from '@cian/ui-kit';
import { UIText2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './Railways.css';
import { GeoTravelTime } from './GeoTravelTime';
import { ETransportType, ETravelType, IRailwaySchema } from '../../../types/geo';

export interface IProps {
  railway: IRailwaySchema;
}

const TRAVEL_TYPE_TO_TRANSPORT_TYPE_MAP: { [key in ETravelType]: ETransportType } = {
  [ETravelType.ByCar]: ETransportType.Transport,
  [ETravelType.ByFoot]: ETransportType.Walk,
};

export function Railways({ railway }: IProps) {
  const { id, name, time, travelType } = railway;

  return (
    <div key={id} className={styles['container']}>
      <div className={styles['icon']}>
        <IconTrain16 display="block" color="black_100" />
      </div>
      <span className={styles['name']}>
        <UIText2 display="inline">{name}</UIText2>
      </span>
      {time && travelType && (
        <GeoTravelTime time={Number(time)} transportType={TRAVEL_TYPE_TO_TRANSPORT_TYPE_MAP[travelType]} />
      )}
    </div>
  );
}
